<template>
    <v-flex class="guest-form">
    <v-flex class="main px-5">
      <v-flex class="field">
      <span>Who is this booking for?</span>
      <div class="check-box-container">
        <div class="check-box" @click="cardCheckboxClick(userType)"
          v-for="userType in userTypes" :key="userType">
          <CardCheckbox :label="userType" :isSelected="userType === selectedUserType" />
        </div>
      </div>
      </v-flex>
      <v-flex class="field">
        <span>First name</span>
        <TextField
          @emitTextValue="updateField($event, 'firstName')"
          label
          @emitOnBlur="nameErrorHandler = true"
          :rules="nameErrorHandler ? [FORM_VALIDATION.required, FORM_VALIDATION.firstname] : []"
          placeholder="E.g Harry"
          :value="guest.firstName"
        />
      </v-flex>
      <v-flex class="field">
        <span>Last name</span>
        <TextField
          @emitTextValue="updateField($event, 'lastName')"
          label
          @emitOnBlur="nameErrorHandler = true"
          :rules="nameErrorHandler ? [FORM_VALIDATION.required, FORM_VALIDATION.lastname] : []"
          placeholder="E.g How"
          :value="guest.lastName"
        />
      </v-flex>
      <v-flex class="field">
        <span>Email address</span>
        <TextField
          @emitTextValue="updateField($event, 'email')"
          label
          @emitOnBlur="emailErrorHandler = true"
          :rules="emailErrorHandler ? emailRules : []"
          placeholder="E.g hello@timeflyz.com"
          :value="getEmail(guest.email)"
        />
      </v-flex>
      <v-flex class="field">
        <span>Mobile number</span>
        <vue-tel-input v-if="!loadingInProgress" :autoDefaultCountry="guest?.mobile ? false : true" v-model="guest.mobile"  @validate="validateMobileNumber"></vue-tel-input>
        <span class="phone-error" v-if="mobileError !== ''">{{ mobileError }}</span>
      </v-flex>
        <v-flex class="mt-5 field">
            <span class="label">Date of birth (guests must be above 18)</span>
              <v-menu
                v-model="showDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedDate"
                  outlined
                  append-icon="mdi-calendar"
                  readonly
                  single-line
                  :rules="[FORM_VALIDATION.required]"
                  v-bind="attrs"
                  v-on="on"
                  @click:append="showDate = true"
                  clearable
                  @click:clear="resetDate"
                ></v-text-field>
                </template>
                <v-date-picker
                no-title
                :value="now"
                @input="inputDate"
                :max="maxDate"
                ></v-date-picker>
              </v-menu>
          </v-flex>
    </v-flex>
    <!--<div v-if="!isLoggedIn" class="main password-form">
      <div class="create-account">
        Create your Timeflyz account
      </div>
      <div class="create-account-subheader">
        Simply enter a password to create your free account.
        You’ll be able to manage bookings, save favourites, and rebook micro-stays.
      </div>
      <v-flex class="field">
        <span>Password</span>
        <v-text-field
          v-model="guest.password"
          @input="passwordChange"
          placeholder="Password"
          single-line
          outlined
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show ? 'text' : 'password'"
          @click:append="show = !show"
          :rules="[v => v.length > 0 || '*Required*']"
        ></v-text-field>
      </v-flex>
    </div>-->
    <v-flex class="px-5">
        <span class="label">Comments</span>
        <v-flex>
            <TextArea
            :value="guest.comments"
            @emitTextValue="updateField($event, 'comments')"
            placeholder="E.g Extra towels please..."
            />
        </v-flex>
    </v-flex>
    <!-- <v-flex v-if="guest?.selectedUserType === 'Myself'" d-flex class="mb-3 checkbox-controls px-5">
				<v-checkbox v-model="guest.isChecked" color="#ff4500" label="Update mobile and dob to my profile" hide-details></v-checkbox>
		</v-flex> -->
    <!--<v-snackbar :timeout="timeout" v-model="snackbar" bottom
    right>{{ toasterMessage }}</v-snackbar>-->
    <div v-if="openDialog">
      <v-dialog v-model="openDialog" max-width="370px">
        <Dialog
          :dialogData="toasterMessage"
          :onCloseDialog="closeDialog"
          mode="snackbar-error"
        />
      </v-dialog>
    </div>
  </v-flex>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import moment from 'moment';
import eventBus from '@/eventBus';
import CardCheckbox from '@/common/CardCheckbox.vue';
import Dialog from '@/common/ErrorDialog.vue';
import 'vue-tel-input/dist/vue-tel-input.css';
import {
  SNACKBAR_TIMEOUT,
  HTTP_STATUS_CODE,
  TOASTER_MESSAGE,
} from '@/constants';
import validation from '@/validations';

const {
  getLoggedInUserDetailService,
} = require('@/services/user/user.service');

export default {
  data() {
    return {
      emailRules: [validation.FORM_VALIDATION.required, validation.FORM_VALIDATION.email],
      isFormValid: false,
      userTypes: ['Myself', 'Someone else'],
      snackbar: false,
      openDialog: false,
      timeout: SNACKBAR_TIMEOUT,
      toasterMessage: '',
      emailErrorHandler: false,
      nameErrorHandler: false,
      show: false,
      isLoggedIn: false,
      mobileErrorHandler: false,
      FORM_VALIDATION: validation.FORM_VALIDATION,
      mobileError: '',
      loadingInProgress: true,
      showDate: false,
      maxDate: moment().subtract(18, 'years').format('YYYY-MM-DD'),
      now: moment().subtract(18, 'years').format('YYYY-MM-DD'),
      dob: '',
    };
  },
  components: {
    CardCheckbox,
    Dialog,
  },
  computed: {
    ...mapGetters({
      guest: 'guests',
    }),
    ...mapState({
      selectedUserType: state => state.bookings.selectedUserType,
    }),
    computedDate() {
      return this.dob
        ? moment(this.dob).format('ddd DD MMM, YYYY')
        : '';
    },
  },
  watch: {
    guest: {
      handler(newValue) {
        this.dob = newValue?.dob;
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    this.isLoggedIn = window.$cookies.get('token') ? true : false;
    // Note this condition is to check if the user details is set already
    // when the user navigate back form payment page
    if (!this.guest.firstName) {
      this.getUserDetails();
    } else {
      this.loadingInProgress = false;
    }
  },
  methods: {
    ...mapActions({
      setField: 'setField',
      setSelectedUserType: 'setSelectedUserType',
      setBookingFormValidate: 'setBookingFormValidate',
    }),
    validateMobileNumber(telnumber) {
      this.mobileError = '';
      [
        validation.FORM_VALIDATION.mobile(telnumber.formatted),
        this.mobileErrorHandler ? validation.FORM_VALIDATION.required(telnumber.formatted) : true,
      ].every((el) => {
        if (el !== true) {
          this.mobileError = el;
          return false;
        }
        return true;
      });
    },
    async updateField(value, field) {
      this.setField({ value, field });
      this.checkValidation();
    },
    passwordChange() {
      this.checkValidation();
    },
    checkValidation() {
      if (!this.isLoggedIn) {
        if (this.guest?.email && this.guest?.firstName && this.guest?.lastName && this.guest?.dob
        && validation.FORM_VALIDATION.email(this.guest.email) === true && this.mobileError === '') {
          this.isFormValid = true;
        } else {
          this.isFormValid = false;
        }
      } else if (this.guest?.email && this.guest?.firstName && this.guest?.lastName && this.guest?.dob
       && validation.FORM_VALIDATION.email(this.guest.email) === true && this.mobileError === '') {
        this.isFormValid = true;
      } else {
        this.isFormValid = false;
      }
      eventBus.$emit('passFormValidValue', this.isFormValid);
      this.setBookingFormValidate(this.isFormValid);
    },
    cardCheckboxClick(type) {
      this.mobileErrorHandler = false;
      this.mobileError = '';
      this.setSelectedUserType(type);
      if (type === 'Myself') {
        this.getUserDetails();
      } else {
        this.resetFields();
      }
      this.setField({ value: type, field: 'selectedUserType' });
    },
    async getUserDetails() {
      try {
        if (this.isLoggedIn) {
          this.loadingInProgress = true;
          const getLoggedInUserResponse = await getLoggedInUserDetailService();
          if (getLoggedInUserResponse.status === HTTP_STATUS_CODE.SUCCESS) {
            this.setField({ value: getLoggedInUserResponse.data.firstName, field: 'firstName' });
            this.setField({ value: getLoggedInUserResponse.data.lastName, field: 'lastName' });
            this.setField({ value: getLoggedInUserResponse.data.mobile, field: 'mobile' });
            this.setField({ value: getLoggedInUserResponse.data.email, field: 'email' });
            this.setField({ value: getLoggedInUserResponse.data?.dob ? moment(getLoggedInUserResponse.data.dob).format('YYYY-MM-DD') : '', field: 'dob' });
            this.setField({ value: false, field: 'isChecked' });
            this.loadingInProgress = false;
            this.checkValidation();
          } else {
            this.loadingInProgress = false;
            throw new Error();
          }
        }
      } catch (err) {
        this.snackbar = true;
        this.openDialog = true;
        this.toasterMessage = TOASTER_MESSAGE.TROUBLE;
      }
    },
    closeDialog() {
      this.snackbar = false;
      this.openDialog = false;
    },
    resetFields() {
      this.emailErrorHandler = false;
      this.nameErrorHandler = false;
      this.setField({ value: '', field: 'firstName' });
      this.setField({ value: '', field: 'lastName' });
      this.setField({ value: '', field: 'mobile' });
      this.setField({ value: '', field: 'email' });
      this.setField({ value: '', field: 'dob' });
      this.setField({ value: false, field: 'isChecked' });
      this.checkValidation();
    },
    inputDate(text) {
      this.setField({ value: text, field: 'dob' });
      this.dob = text;
      this.checkValidation();
      this.showDate = false;
    },
    resetDate() {
      this.setField({ value: '', field: 'dob' });
      this.checkValidation();
    },
    getEmail(email) {
      if (email.includes('privaterelay.appleid.com')) {
        this.setField({ value: true, field: 'updateEmail' });
        return '';
      }
      return email;
    },
  },
};
</script>

<style lang="scss">
.guest-form {
  font-family: Abel;
  line-height: 20px;
  .comments-section {
      .label {
          font-size: 18px;
          line-height: 24px;
      }
  }
  .check-box-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 2px 0 15px 0;
    .check-box {
      width: 49%;
    }
  }
  .password-form {
    background: #F7F7FC;
    padding: 24px 21px;
    .create-account {
      font-size: 18px;
    }
    .create-account-subheader {
      padding: 15px 0;
    }
  }
}
.phone-error {
    color: #F00;
}
</style>
