<template>
<v-flex class="book-a-hotel-footer px-12">
    <v-flex xs12>
      <Button
        height="40px"
        color="primary"
        value="Continue"
        @buttonClicked="goToLink"
        buttonClass="continue-button"
        :disabled="isDisabled && !bookingFormValidate"
      />
    </v-flex>
    <div v-if="openLoginDialog">
      <v-dialog v-model="openLoginDialog" max-width="max-content">
        <Dialog
          @loginSuccessful="loginSuccessful()"
          :onCloseDialog="closeDialog"
          mode="login"
          title="Login"
          :isNotRedirect="true"
          :guestDetails="currentData"
          alertActionMessage="Cancel booking"
          @emitAuthType="emitAuthType"
        />
      </v-dialog>
    </div>
    <div v-if="openSignupDialog">
      <v-dialog v-model="openSignupDialog" max-width="370px">
        <Dialog
          @registerSuccessful="registerSuccessful()"
          :onCloseDialog="closeDialog"
          mode="signup"
          title="Register"
          :isNotRedirect="true"
          :guestDetails="currentData"
          alertActionMessage="Cancel booking"
          @emitAuthType="emitAuthType"
        />
      </v-dialog>
    </div>
    <div v-if="openGuestDialog">
      <v-dialog v-model="openGuestDialog" max-width="370px">
        <Dialog
          :onGuestUpdatePassword="openGuestPassDialog"
          :onCloseDialog="receiveGuestToken"
          mode="guestDialog"
          title="Setup Account"
          :guestDetails="currentData"
        />
      </v-dialog>
    </div>
    <div v-if="openPassDialogForGuest">
      <v-dialog v-model="openPassDialogForGuest" max-width="370px">
        <Dialog
          @updateSuccessful="createGuestPass()"
          :onCloseDialog="receiveGuestToken"
          mode="guestSetPasswordDialog"
          title="Create Password"
          :guestDetails="currentData"
        />
      </v-dialog>
    </div>
    <!--<v-snackbar
      v-model="snackbar"
      bottom right
      :timeout="timeout"
    >
      {{ error }}
    </v-snackbar>-->
    <div v-if="openDialog">
      <v-dialog v-model="openDialog" max-width="370px">
        <ErrorDialog
          :dialogData="error"
          :onCloseDialog="closeErrorDialog"
          mode="snackbar-error"
        />
      </v-dialog>
    </div>
    <div v-if="openBlockDialog">
      <v-dialog v-model="openBlockDialog" max-width="370px">
        <ErrorDialog
          :dialogData="dialogMessage"
          :onCloseDialog="closeDialog"
          mode="snackbar-error"
        />
      </v-dialog>
    </div>
</v-flex>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import eventBus from '@/eventBus';
import ErrorDialog from '@/common/ErrorDialog.vue';
import {
  SNACKBAR_TIMEOUT,
  HTTP_STATUS_CODE,
  TOASTER_MESSAGE,
} from '@/constants';

const {
  checkEmailExist,
  getLoggedInUserDetailService,
  updateUserDetailService,
  checkBanUserService,
  updateAliasAppleEmail,
} = require('@/services/user/user.service');

export default {
  props: {
    link: {
      type: String,
      required: true,
      default: 'confirm',
    },
  },
  data() {
    return {
      isDisabled: true,
      currentRoute: '',
      openDialog: false,
      snackbar: false,
      timeout: SNACKBAR_TIMEOUT,
      openLoginDialog: false,
      openGuestDialog: false,
      openPassDialogForGuest: false,
      openSignupDialog: false,
      currentData: [],
      userID: null,
      openBlockDialog: false,
      dialogMessage: 'Unfortunately we cannot process a booking for you at this property. Please contact the property directly.',
    };
  },
  components: {
    ErrorDialog,
  },
  computed: {
    ...mapGetters({
      bookingAmount: 'bookingAmount',
      guestDetails: 'guests',
      booking: 'booking',
      bookingFormValidate: 'bookingFormValidate',
    }),
    ...mapState({
      error: state => state.user.error,
    }),
  },
  created() {
    eventBus.$on('passFormValidValue', (isFormValid) => {
      this.isDisabled = !isFormValid;
    });
  },
  mounted() {
    this.currentRoute = this.$router.history.current.fullPath;
    if (this.currentRoute === '/book-hotel/confirm') {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  },
  watch: {
    $route(to) {
      this.currentRoute = to.path;
      if (this.currentRoute === '/book-hotel/confirm') {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },
    error() {
      if (this.error !== null) {
        this.snackbar = true;
        this.openDialog = true;
      }
      // eslint-disable-no-useless-concat
      /* if (this.error === '"' + `User with email ${this.guestDetails.email}
      already exists` + '"') {
        this.openLoginDialog = true;
      } */
      // setTimeout(() => this.resetError(), SNACKBAR_TIMEOUT);
    },
  },
  methods: {
    emitAuthType() {
      if (this.openLoginDialog) {
        this.openLoginDialog = false;
        this.openSignupDialog = true;
      } else {
        this.openLoginDialog = true;
        this.openSignupDialog = false;
      }
    },
    ...mapActions({
      setBookingStepStatus: 'setBookingStepStatus',
      signUpUser: 'signUpUser',
      resetUserError: 'resetUserError',
      loginUser: 'loginUser',
      registerGuestUser: 'registerGuestUser',
      guestToken: 'guestToken',
      setError: 'setError',
    }),
    async goToLink() {
      if (this.currentRoute === '/book-hotel/confirm' && !window.$cookies.get('token')) {
        this.openLoginDialog = true;
        setTimeout(() => {
          eventBus.$emit('checkHeaderAccess');
        }, SNACKBAR_TIMEOUT);
      } else if (this.currentRoute === '/book-hotel/guests' && !window.$cookies.get('token')) {
        this.currentData = this.guestDetails;
        const checkAlreadyExistEmail = await checkEmailExist(this.guestDetails.email);
        if (checkAlreadyExistEmail.data === false) {
          // this.openSignupDialog = true;
          this.register(this.guestDetails);
          setTimeout(() => {
            eventBus.$emit('checkHeaderAccess');
          }, SNACKBAR_TIMEOUT);
        } else if (checkAlreadyExistEmail.data === 'Guest') {
          this.openGuestDialog = true;
          setTimeout(() => {
            eventBus.$emit('checkHeaderAccess');
          }, SNACKBAR_TIMEOUT);
        } else {
          this.openLoginDialog = true;
          setTimeout(() => {
            eventBus.$emit('checkHeaderAccess');
          }, SNACKBAR_TIMEOUT);
        }
      } else if (this.currentRoute === '/book-hotel/guests' && window.$cookies.get('token') && this.guestDetails.isChecked) {
        await this.updateUserDetails();
      } else if (this.guestDetails.updateEmail && this.guestDetails.selectedUserType === 'Myself') {
        const userDetails = {
          email: this.guestDetails.email,
        };
        const updatedUserResponse = await updateAliasAppleEmail(userDetails);
        if (updatedUserResponse.status === HTTP_STATUS_CODE.SUCCESS) this.routeToNext();
      } else {
        this.routeToNext();
      }
    },
    async routeToNext() {
      await this.getLoggedInUserDetails();
      const userDetails = this.userID;
      const userDetailsCheck = {
        mobile: userDetails.mobile,
        email: userDetails.email,
        hotelId: this.booking._id,
      };
      const updatedUserResponse = await checkBanUserService(userDetailsCheck);
      if (userDetails && updatedUserResponse.status === 201 && updatedUserResponse.data.checkingStatus === false) {
        this.openBlockDialog = true;
      } else {
        const { link, $route } = this;
        this.setBookingStepStatus({ step: $route.name, status: 'complete' });
        // eslint-disable-next-line
        /***
         *  Remove null link and handle payment and api call, null is used as
         * reference to denote the continue button is clicked from payment screen
         */
        if (link !== 'null') {
          this.$router.push(`/book-hotel/${link}`);
        }
      }
    },
    async updateUserDetails() {
      try {
        const userDetails = {
          firstName: this.guestDetails.firstName,
          lastName: this.guestDetails.lastName,
          fullName: `${this.guestDetails.firstName} ${this.guestDetails.lastName}`,
          mobile: this.guestDetails.mobile,
          email: this.guestDetails.email,
          dob: this.guestDetails.dob,
        };
        const updatedUserResponse = await updateUserDetailService(userDetails);
        if (updatedUserResponse.status === HTTP_STATUS_CODE.SUCCESS) {
          this.snackbar = true;
          this.openDialog = true;
          this.setError(TOASTER_MESSAGE.CHANGES_SAVED);
          this.routeToNext();
        } else {
          throw new Error();
        }
      } catch (err) {
        this.snackbar = true;
        this.openDialog = true;
        this.setError(TOASTER_MESSAGE.TROUBLE);
      }
    },
    async getLoggedInUserDetails() {
      try {
        const getLoggedInUserResponse = await getLoggedInUserDetailService();
        if (getLoggedInUserResponse.status === HTTP_STATUS_CODE.SUCCESS) {
          this.userID = getLoggedInUserResponse.data;
        } else {
          throw new Error();
        }
      } catch (err) {
        this.toasterMessage = TOASTER_MESSAGE.TROUBLE;
      }
    },
    resetError() {
      this.snackbar = false;
      this.resetUserError();
    },
    closeErrorDialog() {
      this.snackbar = false;
      this.openDialog = false;
      this.resetUserError();
    },
    closeDialog() {
      this.openLoginDialog = false;
      this.openSignupDialog = false;
      this.openGuestDialog = false;
      this.openBlockDialog = false;
    },
    loginSuccessful() {
      if (!this.error) {
        this.routeToNext();
      }
    },
    async register(userDetails) {
      const details = {
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        fullName: `${userDetails.firstName} ${userDetails.lastName}`,
        email: userDetails.email,
        mobile: userDetails.mobile,
        // password: this.password,
        userType: 'APP',
        provider: 'Guest',
      };
      await this.registerGuestUser(details);
      this.registerSuccessful();
    },
    registerSuccessful() {
      if (!this.error) {
        this.routeToNext();
      }
    },
    createGuestPass() {
      if (!this.error) {
        this.routeToNext();
      }
    },
    openGuestPassDialog() {
      this.openGuestDialog = false;
      this.openPassDialogForGuest = true;
    },
    async receiveGuestToken() {
      const payload = {
        email: this.guestDetails.email,
        userType: 'APP',
      };
      this.openGuestDialog = false;
      await this.guestToken(payload);
      this.registerSuccessful();
    },
    destroyToken() {
      this.$cookies.remove('token');
      this.$cookies.remove('isLoggedIn');
      window.location.href = '/';
    },
  },
};
</script>

<style lang="scss">
.book-a-hotel-footer {
    display: flex;
    .booking-amount {
        font-size: 24px;
        line-height: 32px;
        color: #080914;
    }
  .continue-button {
      width: 100%;
  }
}
</style>
