<template>
  <TfCard>
    <template v-slot:title-text>
      <span><b>Guests</b></span>
    </template>
    <template v-slot:title-icon>
      <!-- <img class="pointer" src="@/assets/info.svg" alt="help" /> -->
    </template>
    <template v-slot:content>
      <GuestForm />
    </template>
    <template v-slot:footer>
      <book-a-hotel-footer link="payment" />
    </template>
  </TfCard>
</template>

<script>
import BookAHotelFooter from '../BookAHotelFooter.vue';
import GuestForm from './GuestForm.vue';

export default {
  components: {
    BookAHotelFooter,
    GuestForm,
  },
};
</script>

<style>
</style>
